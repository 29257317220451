
import { defineComponent} from "vue";

export default defineComponent({
  name: "profile",
  setup: () => {

    return{}
  }

});
